import React, {useEffect, useState} from "react"
import { useHistory } from "react-router-dom";

import {
  withTheme, Grid, Container, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, IconButton
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import ImageMinhasSolicitacoes from '../../assets/img/binoculars.png'
import MoneyBagIcon from '../../assets/img/save-money.png';
import Cash from '../../assets/img/cash.png'

import FormContext from '../FormContext';
import Header from "./Header"
import Footer from "./Footer"
import Breadcrumb from '../Breadcrumb'
import Card from '../Card'
import Content from "../Content";

const styles = theme => ({
  progressWrapper:{
    position: 'relative',
    maxWidth: theme.queroFinanciar.contentMaxWidth,
    padding: theme.spacing(2),
    width: '85%',
    [theme.breakpoints.up(theme.queroFinanciar.contentMaxWidth+100)]:{
      width: '100%',
      margin: '0 auto',
    }
  },
  progressBar:{
    position: 'relative',
    height: theme.spacing(),
    marginTop: theme.spacing(2),
  },
  content: {
    margin: '20px 0px 20px 0px',
  },
  aviso: {
    borderLeft: '3px solid #00b572',
    marginTop: 15,
    marginLeft: 2
  },
  warning: {
    borderLeft: '3px solid #FFC211',
    marginTop: 15,
    marginLeft: 2,
    cursor: 'pointer'
  }
})

const loggedArea = (props) => {

  const context = React.useContext(FormContext);
  const history = useHistory();

  const [showPopupPaginaInicial,setShowPopupPaginaInicial] = useState(false);
  const [proposalsWithPendencies, setProposalsWithPendencies] = useState();

  useEffect(()=>{
    if(context.state.feature_flag_popup_pagina_inicial){
      setShowPopupPaginaInicial(context.state.feature_flag_popup_pagina_inicial);
    }
    const getProposalData = async () => {
      if (context.state.feature_flag_api_v2_sim === true) {
        try{
          const token = await context.getFirebaseToken();
          const firebaseUserId = await context.getFirebaseUserId()
          const proposalResult = await axios.get(`${context.state.API_ENDPOINT}/leads/${firebaseUserId}/proposals/pending-docs`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const resultData = proposalResult.data
          const proposalData = resultData.data ? resultData.data : resultData
          setProposalsWithPendencies(proposalData)
        }catch(error){
          console.log(error)
          // setProposalData(null)
        }
        // props.updateResize()
      }
    }
    getProposalData()
  },[context.state.firebaseLoaded]);

  if(!context.state.firebaseLoaded) return null;

  if (context.state.personalDataUpdateStatus === 'success') {
    window.scrollTo({ top: 100, behavior: 'smooth' });
  }


  return <>
    <Header>
    <div className={props.classes.progressWrapper}>
      <Typography>Meu perfil</Typography>
      <div className={props.classes.progressBar}>
        <div style={{
          position: 'absolute',
          width: '100%',
          height: props.theme.spacing(.5),
          background: props.theme.palette.common.black,
          opacity: .1,
        }}></div>
        <div style={{
          position: 'absolute',
          width: `100%`,
          background: props.theme.palette.common.white,
          height: props.theme.spacing(.5),
          opacity: .9,
          transition: 'width cubic-bezier(.71,.01,.39,1.01) 1s',
        }}></div>
      </div>
    </div>
    </Header>
    <Content>
      <Breadcrumb actualPage="Área Logada"/>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography variant="h4">Olá, {context.state.values.nome}!</Typography>
          <Typography variant="h5">Bem vindo à Quero Financiar!</Typography>
          {/* <Typography style={{marginTop: 20}}>Escolha uma das opções abaixo</Typography> */}
        </Grid>
      </Grid>

      {
        context.state.feature_flag_texto_pagina_inicial &&
          <Grid item container direction="column" alignItems="flex-start" xs={12} className={props.classes.aviso}>
            <Typography variant="h6" gutterBottom style={{ margin: 10, fontSize: 13 }}>
              {context.state.textoPaginaInicial}
            </Typography>
          </Grid>
      }

       {
        context.state.feature_flag_api_v2_sim === true && proposalsWithPendencies !== undefined && proposalsWithPendencies.length !== 0 ?
          proposalsWithPendencies.map(proposal => (
          <Grid key={proposal.id} item container direction="column" alignItems="flex-start" xs={12} className={props.classes.warning} onClick={() => history.push(`/sim-envio-documentos/?proposal=${proposal.id}`)}>
            <Typography variant="h6" gutterBottom style={{ margin: 10, fontSize: 13 }}>
                Ei, você tem uma proposta da <span style={{ color: '#fe0064', fontWeight: 'bold' }}>SIM #{ proposal.externalId}</span> pendente o envio de alguns documentos! Clique aqui e comece a preencher agora mesmo.
            </Typography>
          </Grid>
          ))
          : ''
      }

      {
        context.state.feature_flag_popup_pagina_inicial &&
        <Dialog
          open={showPopupPaginaInicial}
          onClose={() => setShowPopupPaginaInicial(false)}
        >
          <DialogTitle>{context.state.popup_pagina_inicial_titulo}</DialogTitle>
          <DialogContent>
            <DialogContentText>{context.state.popup_pagina_inicial_conteudo}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowPopupPaginaInicial(false)} variant="outlined" style={{color: '#00b572', borderColor: 'white'}}>fechar</Button>
            <Button onClick={() => {
              window.open(context.state.popup_pagina_inicial_url,"_blank");
              // if(String(context.state.popup_pagina_inicial_url).search(window.location.hostname)==-1){
              //   window.location.href = context.state.popup_pagina_inicial_url;
              // }else{
              //   history.push(String(context.state.popup_pagina_inicial_url).replace(window.location.origin,""));
              // }
                }} style={{ color: 'white' }} variant="contained" color="primary">{context.state.popup_pagina_inicial_cta}</Button>
          </DialogActions>
        </Dialog>
      }

      <Grid container spacing={4} className={props.classes.content}>

        <Card
          type="home-logged-area"
          title="Acompanhar meus pedidos"
          route="/area-logada/meus-pedidos"
          image={ImageMinhasSolicitacoes}
          imageAlt="Icone meus pedidos"
        />

        {/* destaque */}
        <Card
          type="home-logged-area"
          title="Solicitar Novo Empréstimo"
          route="/area-logada/novo-emprestimo"
          image={MoneyBagIcon}
          imageAlt="Icone solicitar novo empréstimo"
        />

      </Grid>
    </Content>
    <Footer/>
  </>

}

export default withTheme(withStyles(styles)(loggedArea));
